import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Link } from 'react-router-dom';

const isPathActive = (path) => window.location.pathname === path;

function Home() {
  return (
    <div>
      {/* Header */}
      <header id="site-header" className="header-w3l fixed-top">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light w-100" 
           style={{ backgroundColor: 'gray' }} >
            <a className="navbar-brand" href="/">
              Med<span className="sub-logo">i</span>ck
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav mx-lg-auto">
                <li className="nav-item">
                  <Link to="/" className={`nav-link ${isPathActive('/') ? 'active' : ''}`}>
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/about" className={`nav-link ${isPathActive('/about') ? 'active' : ''}`}>
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/services" className={`nav-link ${isPathActive('/services') ? 'active' : ''}`}>
                    Department
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className={`nav-link ${isPathActive('/contact') ? 'active' : ''}`}>
                    Contact
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/login" className={`nav-link ${isPathActive('/login') ? 'active' : ''}`}>
                    Login
                  </Link>
                </li>
              </ul>
              <form className="d-flex ms-lg-3">
                <input
                  type="search"
                  className="form-control me-2"
                  placeholder="Search here.."
                  aria-label="Search"
                  required
                />
                <button className="btn btn-outline-primary" type="submit">
                  <i className="fa fa-search"></i>
                </button>
              </form>
            </div>
          </nav>
        </div>
      </header>
      
       {/* w3l-banner-content */}
       <div className="banner-w3l-main">
        <div className="w3l-banner-content">
          <div className="container">
            <div className="bannerhny-info text-center">
              {/* <h6 className="title-subhny mb-2">Medical</h6> */}
              <h4  className="text-bold mb-2 text-light">Medick Transforming Real-Time Insights and Comprehensive Financial Control</h4>
              <Link to="/about">
                  <button
                    type="button"
                    className="btn btn-style btn-outline-light mt-sm-5 mt-4 mr-2"
                  >
                    Read More
                  </button>
                </Link>
            </div>
          </div>
        </div>
        
        {/* home page block1 */}
        <section className="home-services pt-lg-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="box-wrap">
                  <div className="box-wrap-grid">
                    <div className="icon">
                      <span className="fa fa-users"></span>
                    </div>
                    <div className="info">
                      <p>Protect</p>
                      <h4><a href="#url">Qualified Team</a></h4>
                    </div>
                  </div>
                  <p className="mt-3">In-depth knowledge of accounting principles,Finance experts ensure the software aligns with the best practices in healthcare revenue management and compliance.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mt-md-0 mt-4">
                <div className="box-wrap">
                  <div className="box-wrap-grid">
                    <div className="icon">
                      <span className="fa fa-shield"></span>
                    </div>
                    <div className="info">
                      <p>Help</p>
                      <h4><a href="#url">Quality Service</a></h4>
                    </div>
                  </div>
                  <p className="mt-3"> Various departments ensuring that charges are accurately recorded for each service. its improves the transparency and accuracy across departments.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mt-lg-0 mt-4">
                <div className="box-wrap">
                  <div className="box-wrap-grid">
                    <div className="icon">
                      <span className="fa fa-globe"></span>
                    </div>
                    <div className="info">
                      <p>World</p>
                      <h4><a href="#url">Global Work</a></h4>
                    </div>
                  </div>
                  <p className="mt-3"> Working with healthcare we continuously gather insights and improve our software to stay ahead of industry trends, benefiting clients in all regions.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* //home page block1 */}
      </div>
      {/* //w3l-banner-content */}

      {/* banner bottom shape */}
      <div className="position-relative">
        <div className="shape overflow-hidden">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      {/* banner bottom shape */}

    
  {/* footer */}
  <section className="w3l-footer-29-main">
    <div className="footer-29 py-5">
      <div className="container py-lg-4">
        <div className="row footer-top-29">
          <div className="col-lg-4 col-md-6 col-sm-7 footer-list-29 footer-1 pr-lg-5">
            <div className="footer-logo mb-3">
              <a className="navbar-brand" href="index.html">Medick</a>
            </div>
            <p>Lorem ipsum dolor sit amet consectetur ipsum elit. Lorem ipsum dolor sit amet elit.</p>
            <ul className="mt-3">
              <li><a href="tel:+(21) 255 999 8888"><span className="fa fa-phone"></span> +(21) 255 999 8888</a></li>
              <li><a href="mailto:medick@mail.com" className="mail"><span className="fa fa-envelope-open-o"></span>
                  medick@mail.com</a></li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-5 col-6 footer-list-29 footer-2 mt-sm-0 mt-5">

            <ul>
             <h6 className="footer-title-29">History</h6>
              <li><a href="about.html">About Us</a></li>
              <li><a href="blog.html"> Blog Posts</a></li>
              <li><a href="services.html">Departments</a></li>
              <li><a href="#careers"> Careers</a></li>
              <li><a href="about.html">Team</a></li>
              <li><a href="contact.html">Contact us</a></li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-5 col-6 footer-list-29 footer-3 mt-lg-0 mt-5">
            <h6 className="footer-title-29">Useful Links</h6>
            <ul>
              <li><a href="#terms">Terms of service</a></li>
              <li><a href="#privacy"> Privacy policy</a></li>
              <li><a href="#doc"> Documentation</a></li>
              <li><a href="#support"> Support</a></li>
              <li><a href="#components"> Components</a></li>
              <li><a href="#changelog"> Changelog</a></li>
            </ul>

          </div>
          <div className="col-lg-4 col-md-6 col-sm-7 footer-list-29 footer-4 mt-lg-0 mt-5">
            <h6 className="footer-title-29">Subscribe to our Newsletter </h6>
            <p>Enter your email and receive the latest news, updates and special offers from us.</p>

            <form action="#" className="subscribe" method="post">
              <input type="email" name="email" placeholder="Your Email Address" required=""/>
              <button className="btn btn-style btn-primary w-100 mt-3">Subscibe</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* //footer */}

  {/* footer */}
  <section className="w3l-footer-29-main">
    <div className="footer-29 py-5">
      <div className="container py-lg-4">
        <div className="row footer-top-29">
          <div className="col-lg-4 col-md-6 col-sm-7 footer-list-29 footer-1 pr-lg-5">
            <div className="footer-logo mb-3">
              <a className="navbar-brand" href="index.html">Medick</a>
            </div>
            <p>Lorem ipsum dolor sit amet consectetur ipsum elit. Lorem ipsum dolor sit amet elit.</p>
            <ul className="mt-3">
              <li><a href="tel:+(21) 255 999 8888"><span className="fa fa-phone"></span> +(21) 255 999 8888</a></li>
              <li><a href="mailto:medick@mail.com" className="mail"><span className="fa fa-envelope-open-o"></span>
                  medick@mail.com</a></li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-5 col-6 footer-list-29 footer-2 mt-sm-0 mt-5">

            <ul>
             <h6 className="footer-title-29">History</h6>
              <li><a href="about.html">About Us</a></li>
              <li><a href="blog.html"> Blog Posts</a></li>
              <li><a href="services.html">Departments</a></li>
              <li><a href="#careers"> Careers</a></li>
              <li><a href="about.html">Team</a></li>
              <li><a href="contact.html">Contact us</a></li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-5 col-6 footer-list-29 footer-3 mt-lg-0 mt-5">
            <h6 className="footer-title-29">Useful Links</h6>
            <ul>
              <li><a href="#terms">Terms of service</a></li>
              <li><a href="#privacy"> Privacy policy</a></li>
              <li><a href="#doc"> Documentation</a></li>
              <li><a href="#support"> Support</a></li>
              <li><a href="#components"> Components</a></li>
              <li><a href="#changelog"> Changelog</a></li>
            </ul>

          </div>
          <div className="col-lg-4 col-md-6 col-sm-7 footer-list-29 footer-4 mt-lg-0 mt-5">
            <h6 className="footer-title-29">Subscribe to our Newsletter </h6>
            <p>Enter your email and receive the latest news, updates and special offers from us.</p>

            <form action="#" className="subscribe" method="post">
              <input type="email" name="email" placeholder="Your Email Address" required=""/>
              <button className="btn btn-style btn-primary w-100 mt-3">Subscibe</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* //footer */}


    </div>
  );
}


 

export default Home;