import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Link } from 'react-router-dom';

const isPathActive = (path) => window.location.pathname === path;

function Appointment() {
  return (
    <div>
      {/* Header */}
      <header id="site-header" className="header-w3l fixed-top">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light w-100" 
           style={{ backgroundColor: 'gray' }}>
            <a className="navbar-brand" href="/">
              Med<span className="sub-logo">i</span>ck
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav mx-lg-auto">
                <li className="nav-item">
                  <Link to="/" className={`nav-link ${isPathActive('/') ? 'active' : ''}`}>
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/about" className={`nav-link ${isPathActive('/about') ? 'active' : ''}`}>
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/services" className={`nav-link ${isPathActive('/services') ? 'active' : ''}`}>
                    Department
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className={`nav-link ${isPathActive('/contact') ? 'active' : ''}`}>
                    Contact
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/dashboard" className={`nav-link ${isPathActive('/dashboard') ? 'active' : ''}`}>
                    Dashboard
                  </Link>
                </li>
              </ul>
              <form className="d-flex ms-lg-3">
                <input
                  type="search"
                  className="form-control me-2"
                  placeholder="Search here.."
                  aria-label="Search"
                  required
                />
                <button className="btn btn-outline-primary" type="submit">
                  <i className="fa fa-search"></i>
                </button>
              </form>
            </div>
          </nav>
        </div>
      </header>

      {/* Main Content */}
      <section className="w3l-content-3 py-5">
        <div className="content-3-info py-3">
          <div className="container py-lg-4">
            <div className="row appointment-formw3">
              <div className="col-lg-6 welcome-left">
                <h3 className="title-w3l two mb-3">Click Here</h3>
                <p className="mb-3">Join here to start a business with Medick</p>
                <Link to="/registration">
                  <button
                    type="button"
                    className="btn btn-style btn-outline-light mt-sm-5 mt-4 mr-2"
                  >
                    Sign Up
                  </button>
                </Link>
              </div>
              <div className="d-flex justify-content-end">
                <div className="appointment-form">
                  <form>
                    <h3 className="text-center">Sign In</h3>
                    <div className="fields-grid">
                      <div className="styled-input mt-4">
                        <input type="text" name="username" placeholder="Username" required />
                      </div>
                      <div className="styled-input mt-4">
                        <input type="password" name="password" placeholder="Password" required />
                      </div>
                    </div>
                    {/* <button type="submit" className="btn btn-style btn-primary  mt-9"> */}

                      {/* Sign In
                    </button> */}
                    <div className="d-flex justify-content-center mt-2"> 
                       <button type="submit" className="btn btn-style btn-primary">
                              Login
                      </button>
                    </div>
                    <div className="text-center mt-3">
                      <a href="#" className="text-decoration-none text-muted">
                        Forgot Password?
                      </a>
                    </div>
                    
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Appointment;
