import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Link } from 'react-router-dom';

const isPathActive = (path) => window.location.pathname === path;


function services() {
  return (
    <div>
      {/* Header */}
      <header id="site-header" className="header-w3l fixed-top">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light w-100"  
          style={{ backgroundColor: 'gray' }}>
            <a className="navbar-brand" href="/">
              Med<span className="sub-logo">i</span>ck
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav mx-lg-auto">
                <li className="nav-item">
                  <Link to="/" className={`nav-link ${isPathActive('/') ? 'active' : ''}`}>
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/about" className={`nav-link ${isPathActive('/about') ? 'active' : ''}`}>
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/services" className={`nav-link ${isPathActive('/services') ? 'active' : ''}`}>
                    Department
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className={`nav-link ${isPathActive('/contact') ? 'active' : ''}`}>
                    Contact
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/login" className={`nav-link ${isPathActive('/login') ? 'active' : ''}`}>
                    Login
                  </Link>
                </li>
              </ul>
              <form className="d-flex ms-lg-3">
                <input
                  type="search"
                  className="form-control me-2"
                  placeholder="Search here.."
                  aria-label="Search"
                  required
                />
                <button className="btn btn-outline-primary" type="submit">
                  <i className="fa fa-search"></i>
                </button>
              </form>
            </div>
          </nav>
        </div>
      </header>

     
    {/*/breadcrumb-bg*/}
    <div class="breadcrumb-bg w3l-inner-page-breadcrumb py-5">
      <div class="container pt-lg-5 pt-md-3 p-lg-4 pb-md-3 my-lg-3">
        <h2 class="title pt-5">Departments</h2>
        <ul class="breadcrumbs-custom-path mt-3 text-center">
          <li><a href="/">Home</a></li>
          <li class="active"><span class="fa fa-arrow-right mx-2" aria-hidden="true"></span> Departments </li>
        </ul>
      </div>
    </div>
  {/*//breadcrumb-bg*/}
  {/* banner bottom shape */}
  <div class="position-relative">
    <div class="shape overflow-hidden">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  {/* banner bottom shape */}
  {/*/serices-6*/}
  <section class="w3l-serices-6 py-5" id="services1">
    <div class="container py-lg-5 py-md-4 py-2">
      <div class="title-content text-center">
        <h6 class="title-subhny text-center">
          Services
        </h6>
        <h3 class="title-w3l mb-sm-5 mb-4 pb-sm-o pb-2 text-center">Our Departments</h3>
      </div>
      <div class="grids-area-hny text-center row mt-lg-4">
        <div class="col-lg-4 col-md-6 grids-feature">
          <div class="area-box icon-blue">
              <a href="#"> <img src="assets/images/g1.jpg" alt="" class="img-fluid radius-image"/></a>
              <h4><a href="#feature" class="title-head">Prosthodontist</a></h4>
              {/*/des*/}
                <div class="w3doctor-box-bottom">
                  <div class="doctor-phone"><p><a href="tel:+1(21) 234 4567">+1(21) 234 557 4567</a></p></div>
                <div class="social-icons-dr">
                  <a href="#url" class="twitter"><span class="fa fa-twitter"></span></a>
                  <a href="#url" class="facebook"><span class="fa fa-facebook"></span></a>
                </div>
              </div>
            {/*//des*/}
          </div>
        </div>
        <div class="col-lg-4 col-md-6 grids-feature mt-md-0 mt-4">
          <div class="area-box icon-pink">
            <a href="#"> <img src="assets/images/g2.jpg" alt="" class="img-fluid radius-image"/></a>
            <h4><a href="#feature" class="title-head">Orthodontist</a></h4>
             {/*/des*/}
             <div class="w3doctor-box-bottom">
              <div class="doctor-phone"><p><a href="tel:+1(21) 234 4588">+1(21) 234 557 4588</a></p></div>
            <div class="social-icons-dr">
              <a href="#url" class="twitter"><span class="fa fa-twitter"></span></a>
              <a href="#url" class="facebook"><span class="fa fa-facebook"></span></a>
            </div>
          </div>
        {/*//des*/}
          </div>
        </div>
        <div class="col-lg-4 col-md-6 grids-feature mt-lg-0 mt-4">
          <div class="area-box icon-yellow">
            <a href="#"> <img src="assets/images/g3.jpg" alt="" class="img-fluid radius-image"/></a>
            <h4><a href="#feature" class="title-head">Oral Health Therapist</a></h4>
            {/*/des*/}
            <div class="w3doctor-box-bottom">
              <div class="doctor-phone"><p><a href="tel:+1(21) 234 4597">+1(21) 234 557 4597</a></p></div>
            <div class="social-icons-dr">
              <a href="#url" class="twitter"><span class="fa fa-twitter"></span></a>
              <a href="#url" class="facebook"><span class="fa fa-facebook"></span></a>
            </div>
          </div>
        {/*//des*/}
          </div>
        </div>
        <div class="col-lg-4 col-md-6 grids-feature mt-lg-5 mt-4">
          <div class="area-box icon-yellow">
            <a href="#"> <img src="assets/images/g4.jpg" alt="" class="img-fluid radius-image"/></a>
            <h4><a href="#feature" class="title-head">
              Implantologist</a></h4>
           {/*/des*/}
           <div class="w3doctor-box-bottom">
            <div class="doctor-phone"><p><a href="tel:+1(21) 234 4597">+1(21) 234 557 4597</a></p></div>
          <div class="social-icons-dr">
            <a href="#url" class="twitter"><span class="fa fa-twitter"></span></a>
            <a href="#url" class="facebook"><span class="fa fa-facebook"></span></a>
          </div>
        </div>
      {/*//des*/}
          </div>
        </div>
        <div class="col-lg-4 col-md-6 grids-feature mt-lg-5 mt-4">
          <div class="area-box icon-yellow">
            <a href="#"> <img src="assets/images/g5.jpg" alt="" class="img-fluid radius-image"/></a>
            <h4><a href="#feature" class="title-head">Orthodontist</a></h4>
           {/*/des*/}
           <div class="w3doctor-box-bottom">
            <div class="doctor-phone"><p><a href="tel:+1(21) 234 4567">+1(21) 234 557 4567</a></p></div>
          <div class="social-icons-dr">
            <a href="#url" class="twitter"><span class="fa fa-twitter"></span></a>
            <a href="#url" class="facebook"><span class="fa fa-facebook"></span></a>
          </div>
        </div>
      {/*//des*/}
          </div>
        </div>
        <div class="col-lg-4 col-md-6 grids-feature mt-lg-5 mt-4">
          <div class="area-box icon-yellow">
            <a href="#"> <img src="assets/images/g6.jpg" alt="" class="img-fluid radius-image"/></a>
            <h4><a href="#feature" class="title-head">
              Cosmetic Dentist</a></h4>
             {/*/des*/}
             <div class="w3doctor-box-bottom">
              <div class="doctor-phone"><p><a href="tel:+1(21) 234 4569">+1(21) 234 557 4569</a></p></div>
            <div class="social-icons-dr">
              <a href="#url" class="twitter"><span class="fa fa-twitter"></span></a>
              <a href="#url" class="facebook"><span class="fa fa-facebook"></span></a>
            </div>
          </div>
        {/*//des*/}
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*//services-6*/}
    </div>
  )
}

export default services