import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link } from 'react-router-dom';

const isPathActive = (path) => window.location.pathname === path;

function HospitalProfile() {
  return (
   <div className='' style={{backgroundColor: '#1c2d55'}}>
    {/* Header */}
    <header id="site-header" className="header-w3l fixed-top">
      <div className="container-fluid">
        <nav
          className="navbar navbar-expand-lg navbar-light w-100"
          style={{ backgroundColor: '#1c2d55' }}  
        >
          <a className="navbar-brand text-white" href="/">
            Med<span className="sub-logo">i</span>ck
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav mx-lg-auto">
              <li className="nav-item">
                <Link to="/dashboard" className={`nav-link ${isPathActive('/dashboard') ? 'active' : ''}`}>
                  Dashboard
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/income" className={`nav-link ${isPathActive('/income') ? 'active' : ''}`}>
                  Income
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/expenses" className={`nav-link ${isPathActive('/expenses') ? 'active' : ''}`}>
                  Expenses
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/patients" className={`nav-link ${isPathActive('/patients') ? 'active' : ''}`}>
                  Patients
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/calendar" className={`nav-link ${isPathActive('/calendar') ? 'active' : ''}`}>
                  Calendar
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/profile" className={`nav-link ${isPathActive('/profile') ? 'active' : ''}`}>
                  Profile
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className={`nav-link ${isPathActive('/') ? 'active' : ''}`}>
                  Logout
                </Link>
              </li>
            </ul>
            <form className="d-flex ms-lg-5">
              <input
                type="search"
                className="form-control me-8"
                placeholder="Search here.."
                aria-label="Search"
                required
              />
              <button className="btn btn-outline-light" type="submit">
                <i className="fa fa-search"></i>
              </button>
            </form>
          </div>
        </nav>
      </div>
    </header>

    {/* Profile Section */}
    <div className="container mt-5 pt-5">
      <div className="emp-profile bg-white p-4 rounded shadow-lg">
        <form method="post">
          <div className="row">
            {/* Profile Image */}
            <div className="col-md-4 text-center">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS52y5aInsxSm31CvHOFHWujqUx_wWTS9iM6s7BAm21oEN_RiGoog"
                alt="Hospital Logo"
                className="img-fluid rounded-circle mb-3"
              />
              <div className="file btn btn-primary btn-lg w-100 w-md-auto my-2">
                Change Photo
                <input type="file" name="file" />
              </div>
            </div>

            {/* Profile Details */}
            <div className="col-md-8">
              <div className="profile-head">
                <h5>Ezlin</h5>
                <h6>123 Health St, Wellness City</h6>
                <p className="profile-rating">CA</p>
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-bs-toggle="tab"
                      href="#about"
                      role="tab"
                      aria-selected="true"
                    >
                      About
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            {/* About Section */}
            <div className="col-md-8 offset-md-4">
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="about" role="tabpanel">
                  <div className="row">
                    <div className="col-md-6">
                      <label>Blood Group</label>
                    </div>
                    <div className="col-md-6">
                      <p>A+</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Registration Number</label>
                    </div>
                    <div className="col-md-6">
                      <p>CH12345</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Designation</label>
                    </div>
                    <div className="col-md-6">
                      <p>Doctor</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Email</label>
                    </div>
                    <div className="col-md-6">
                      <p>aaasssl@example.com</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Phone</label>
                    </div>
                    <div className="col-md-6">
                      <p>+1 234 567 890</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    </div>
  );
}

export default HospitalProfile;
