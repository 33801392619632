// Expenses.jsx
import React from 'react';
import { Container, Table, Row, Col, Card } from 'react-bootstrap';
import './Expenses.css';
import { Link } from 'react-router-dom';

const isPathActive = (path) => window.location.pathname === path;

const expensesData = [
  { id: 1, category: 'Staff Salaries', amount: 500000, period: 'Yearly' },
  { id: 2, category: 'Medical Supplies', amount: 300000, period: 'Yearly' },
  { id: 3, category: 'Utilities', amount: 120000, period: 'Yearly' },
  { id: 4, category: 'Maintenance', amount: 80000, period: 'Yearly' },
  { id: 5, category: 'Administrative Costs', amount: 150000, period: 'Yearly' },
  { id: 6, category: 'Medical Supplies', amount: 25000, period: 'Monthly' },
  { id: 7, category: 'Utilities', amount: 10000, period: 'Monthly' },
  { id: 8, category: 'Maintenance', amount: 7000, period: 'Monthly' },
  { id: 9, category: 'Staff Salaries', amount: 42000, period: 'Monthly' },
  { id: 10, category: 'Administrative Costs', amount: 12500, period: 'Monthly' },
];

const Expenses = () => {
  return (
    <div className=''  style={{ paddingTop: '40px' , backgroundColor:'#1c2d55' }}  >
    {/* Header */}
    <header id="site-header" className="header-w3l fixed-top">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light w-100">
            <a className="navbar-brand" href="/">
              Med<span className="sub-logo">i</span>ck
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav mx-lg-auto">
              <li className="nav-item">
                  <Link to="/dashboard" className={`nav-link ${isPathActive('/dashboard') ? 'active' : ''}`}>
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/income" className={`nav-link ${isPathActive('/income') ? 'active' : ''}`}>
                    Income
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/expenses" className={`nav-link ${isPathActive('/expenses') ? 'active' : ''}`}>
                    Expenses
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/patients" className={`nav-link ${isPathActive('/patients') ? 'active' : ''}`}>
                    Patients
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/calendar" className={`nav-link ${isPathActive('/calendar') ? 'active' : ''}`}>
                    Calendar
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/profile" className={`nav-link ${isPathActive('/profile') ? 'active' : ''}`}>
                    Profile
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/" className={`nav-link ${isPathActive('/') ? 'active' : ''}`}>
                    Logout
                  </Link>
                </li>
              </ul>
              <form className="d-flex ms-lg-5">
                <input
                  type="search"
                  className="form-control me-8"
                  placeholder="Search here.."
                  aria-label="Search"
                  required
                />
                <button className="btn btn-outline-primary" type="submit">
                  <i className="fa fa-search"></i>
                </button>
              </form>
            </div>
          </nav>
        </div>
      </header>

      {/* Main Content */}
      <Container fluid className="d-flex flex-column align-items-center" style={{ paddingTop: '6rem', minHeight: '100vh' }}>
        <Row className="w-75">
          <Col>
            <Card className="shadow-lg border-0">
              <Card.Header className="bg-primary text-light text-center">
                <h2 className="mb-0">Hospital Expenses</h2>
              </Card.Header>
              <Card.Body>
                <Table responsive bordered hover className="text-center">
                  <thead className="table-primary">
                    <tr>
                      <th>#</th>
                      <th>Expense Category</th>
                      <th>Amount (INR)</th>
                      <th>Period</th>
                    </tr>
                  </thead>
                  <tbody>
                    {expensesData.map((expense) => (
                      <tr key={expense.id}>
                        <td>{expense.id}</td>
                        <td>{expense.category}</td>
                        <td>{expense.amount.toLocaleString()}</td>
                        <td>{expense.period}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer className="text-muted text-center">
                Total Expenses: {expensesData.reduce((total, item) => total + item.amount, 0).toLocaleString()} INR
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Expenses;
