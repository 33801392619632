import React from 'react';
import About from './pages/About'; // Correct casing
import Contact from './pages/Contact'; // Correct casing
import Services from './pages/Services'; // Correct casing
import Home from './pages/Home'; // Assuming Home.jsx is correctly named
import Login from './pages/Login';
import Registration from './pages/Registration';
import Dashboard from './pages/Dashboard';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import IncomeExpenseChart from './pages/IncomeExpenseChart';
import WorkCalendar from './pages/WorkCalendar';
import Expenses from './pages/Expenses';
import Patients from './pages/patients';
import Income from './pages/Income';
import Calendar from './pages/Calendar';
import ProfileCard from './pages/ProfileCard';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/services' element={<Services />} />
        <Route path='/about' element={<About />} />
        <Route path='/login' element={<Login />} />
        <Route path='/registration' element={<Registration />} />
        <Route path='/dashboard' element={<Dashboard/>} />
        <Route path='/expenses' element={<Expenses />} />
        <Route path='/incomeexpensechart' element={<IncomeExpenseChart />} />
        <Route path='/workcalender' element={<WorkCalendar />} />
        <Route path='/calendar' element={<Calendar />} />
        <Route path='/patients' element={<Patients />} />
        <Route path='/income' element={<Income />} />
        <Route path='/profile' element={<ProfileCard />} />
       
      </Routes>
    </BrowserRouter>
  );
}

export default App;
