import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import IncomeExpenseChart from './IncomeExpenseChart';
import WorkCalendar from './WorkCalendar';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css'; // For Bootstrap Icons
import '@fortawesome/fontawesome-free/css/all.min.css'; // For Font Awesome

const isPathActive = (path) => window.location.pathname === path;

function Dashboard() {
  return (
    <>
        {/* Header */}
      <header id="site-header" className="header-w3l fixed-top">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light w-100" 
           style={{ backgroundColor: '#1c2d55' }}>
            <a className="navbar-brand" href="/">
              Med<span className="sub-logo">i</span>ck
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav mx-lg-auto">
              <li className="nav-item">
                  <Link to="/dashboard" className={`nav-link ${isPathActive('/dashboard') ? 'active' : ''}`}>
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/income" className={`nav-link ${isPathActive('/income') ? 'active' : ''}`}>
                    Income
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/expenses" className={`nav-link ${isPathActive('/expenses') ? 'active' : ''}`}>
                    Expenses
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/patients" className={`nav-link ${isPathActive('/patients') ? 'active' : ''}`}>
                    Patients
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/calendar" className={`nav-link ${isPathActive('/calendar') ? 'active' : ''}`}>
                    Calendar
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/profile" className={`nav-link ${isPathActive('/profile') ? 'active' : ''}`}>
                    Profile
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/" className={`nav-link ${isPathActive('/') ? 'active' : ''}`}>
                    Logout
                  </Link>
                </li>
              </ul>
              <form className="d-flex ms-lg-5">
                <input
                  type="search"
                  className="form-control me-8"
                  placeholder="Search here.."
                  aria-label="Search"
                  required
                />
                <button className="btn btn-outline-primary" type="submit">
                  <i className="fa fa-search"></i>
                </button>
              </form>
            </div>
          </nav>
        </div>
      </header>
      
      {/* Main Content */}
      <div className="main-content" style={{ backgroundColor: '#1c2d55', paddingTop: '60px' }}>
      
     
         
        <Container className="mt-5">
          {/* Dashboard Cards */}
          <Row className="mb-4">
           
            <Col md={4}>
              <Card className="shadow-sm border-0 mb-4">
                <Card.Body className="text-center">
                  <h5 className="text-primary">
                    <i className="fas fa-x-ray me-2"></i> {/* Font Awesome icon */}
                    Radiology and Imaging
                  </h5>
                  <p className="text-muted">Total income : 34000</p>
                  <p className="text-muted">Total expenses : 34000</p>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="shadow-sm border-0 mb-4">
                <Card.Body className="text-center">
                  <h5 className="text-primary">
                    <i className="fas fa-utensils me-2"></i> {/* Font Awesome icon */}
                    Food and Nutrition Services
                  </h5>
                  <p className="text-muted">Total income : 34000</p>
                  <p className="text-muted">Total expenses : 34000</p>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
            <Card className="shadow-sm border-0 mb-4">
              <Card.Body className="text-center">
                <h5 className="text-primary">
                  <i className="fas fa-user-md me-2"></i> {/* Font Awesome icon */}
                  Surgery Department
                </h5>
                <p className="text-muted">Total income : 34000</p>
                <p className="text-muted">Total expenses : 34000</p>
              </Card.Body>
            </Card>
          </Col>
          </Row>

          <Row className="mb-4">
          <Col md={4}>
                  <Card className="shadow-sm border-0 mb-4">
                    <Card.Body className="text-center">
                      <h5 className="text-primary">
                        <i className="fas fa-pills me-2"></i> {/* Font Awesome icon */}
                        Pharmacy
                      </h5>
                      <p className="text-muted">Total income : 34000</p>
                      <p className="text-muted">Total expenses : 34000</p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card className="shadow-sm border-0 mb-4">
                    <Card.Body className="text-center">
                      <h5 className="text-primary">
                        <i className="fas fa-cogs me-2"></i> {/* Font Awesome icon */}
                        Administration and Billing
                      </h5>
                      <p className="text-muted">Total income : 34000</p>
                      <p className="text-muted">Total expenses : 34000</p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card className="shadow-sm border-0 mb-4">
                    <Card.Body className="text-center">
                      <h5 className="text-primary">
                        <i className="fas fa-flask me-2"></i> {/* Font Awesome icon */}
                        Laboratory Services
                      </h5>
                      <p className="text-muted">Total income : 34000</p>
                      <p className="text-muted">Total expenses : 34000</p>
                    </Card.Body>
                  </Card>
                </Col>
          </Row>

          {/* Income and Expense Graphs */}
          <Row className="mb-4">
            <Col md={6}>
              <Card className="shadow-sm border-0">
                <Card.Body>
                  <h5 className="text-secondary">Monthly Income & Expenses</h5>
                  <IncomeExpenseChart frequency="monthly" />
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="shadow-sm border-0">
                <Card.Body>
                  <h5 className="text-secondary">Yearly Income & Expenses</h5>
                  <IncomeExpenseChart frequency="yearly" />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Calendar */}
          <Row className="mb-4">
            <Col>
              <Card className="shadow-sm border-0">
                <Card.Body>
                  <h5 className="text-secondary">Work Days and Holidays</h5>
                  <WorkCalendar />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
    </div>
    </>
  );
}

export default Dashboard;
