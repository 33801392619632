import React from 'react';
import { Link } from 'react-router-dom';

const isPathActive = (path) => window.location.pathname === path;

function About() {
  return (
    <div>
      {/* Header */}
      <header id="site-header" className="header-w3l fixed-top">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light w-100" 
           style={{ backgroundColor: 'gray' }}>
            <a className="navbar-brand" href="/">
              Med<span className="sub-logo">i</span>ck
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav mx-lg-auto">
                <li className="nav-item">
                  <Link to="/" className={`nav-link ${isPathActive('/') ? 'active' : ''}`}>
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/about" className={`nav-link ${isPathActive('/about') ? 'active' : ''}`}>
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/services" className={`nav-link ${isPathActive('/services') ? 'active' : ''}`}>
                    Department
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className={`nav-link ${isPathActive('/contact') ? 'active' : ''}`}>
                    Contact
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/login" className={`nav-link ${isPathActive('/login') ? 'active' : ''}`}>
                    Login
                  </Link>
                </li>
              </ul>
              <form className="d-flex ms-lg-3">
                <input
                  type="search"
                  className="form-control me-2"
                  placeholder="Search here.."
                  aria-label="Search"
                  required
                />
                <button className="btn btn-outline-primary" type="submit">
                  <i className="fa fa-search"></i>
                </button>
              </form>
            </div>
          </nav>
        </div>
      </header>


      {/*/breadcrumb-bg*/}
    <div class="breadcrumb-bg w3l-inner-page-breadcrumb py-5">
      <div class="container pt-lg-5 pt-md-3 p-lg-4 pb-md-3 my-lg-3">
        <h2 class="title pt-5">About</h2>
        <ul class="breadcrumbs-custom-path mt-3 text-center">
          <li><a href="index.html">Home</a></li>
          <li class="active"><span class="fa fa-arrow-right mx-2" aria-hidden="true"></span> About </li>
        </ul>
      </div>
    </div>
  {/*//breadcrumb-bg*/}
  {/* banner bottom shape */}
  <div class="position-relative">
    <div class="shape overflow-hidden">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  {/* banner bottom shape */}
  {/* //w3l-inner-page-breadcrumb*/}
  <section class="w3l-about-ab" id="about">
    <div class="midd-w3 py-5">
      <div class="container py-lg-5 py-md-4 py-2">
        <div class="imgw3l-ab mb-md-5 mb-3">
          <img src="assets/images/banner2.jpg" alt="" class="radius-image img-fluid"/>
        </div>
        <div class="row">
          <div class="col-lg-5 left-wthree-img">
            <h6 class="title-subhny">About Us</h6>
            <h3 class="title-w3l mb-4">The Best Medics</h3>

          </div>
          <div class="col-lg-7 pl-lg-5 align-self">
            <p class="">Healthcare accounting requires strict adherence to regulatory standards 
              (e.g., HIPAA in the U.S.). Medick ensures compliance with healthcare financial regulations 
              and incorporates advanced security protocols to protect patient and financial data.
            </p>
            <p class="mt-4">Medick assists in tracking inventory for medical supplies and equipment, 
              enabling hospitals to manage resources efficiently, avoid overstocking, and reduce waste.
            </p>
            <Link to="/services">
                  <button
                    type="button"
                    className="btn btn-style btn-outline-light text-dark mt-sm-5 mt-4 mr-2"
                  >
                    Read More
                  </button>
                </Link>
          </div>

        </div>
      </div>
    </div>
  </section>
  {/* /w3l-content-2*/}
  {/* /bottom-grids*/}
  <section class="w3l-bottom-grids-6 py-5" id="services1">
    <div class="container py-lg-5 py-md-4 py-2">
      <div class="title-content text-center">
        <h6 class="title-subhny text-center">Motivation is easy</h6>
        <h3 class="title-w3l mb-sm-5 mb-4 pb-sm-o pb-2 text-center">Our Core Values</h3>
      </div>
      <div class="grids-area-hny text-center row mt-lg-4">
        <div class="col-lg-4 col-md-6 grids-feature">
          <div class="area-box icon-blue">
            <span class="fa fa-lightbulb-o"></span>
            <h4><a href="#feature" class="title-head">Innavation</a></h4>
            <p>Vivamus a ligula quam tesque et libero ut justo, ultrices in. Ut eu leo non. Duis sed et dolor
              amet.</p>

          </div>
        </div>
        <div class="col-lg-4 col-md-6 grids-feature mt-md-0 mt-4">
          <div class="area-box icon-pink">
            <span class="fa fa-handshake-o"></span>
            <h4><a href="#feature" class="title-head">Compassion</a></h4>
            <p>Vivamus a ligula quam tesque et libero ut justo, ultrices in. Ut eu leo non. Duis sed dolor et
              amet.</p>

          </div>
        </div>
        <div class="col-lg-4 col-md-6 grids-feature mt-lg-0 mt-4">
          <div class="area-box icon-yellow">
            <span class="fa fa-podcast"></span>
            <h4><a href="#feature" class="title-head">Integrity</a></h4>
            <p>Vivamus a ligula quam tesque et libero ut justo, ultrices in. Ut eu leo non. Duis sed dolor et
              amet.</p>

          </div>
        </div>
      </div>
    </div>
  </section>
  {/* //bottom-grids*/}
  {/* home page block grids */}
  <section class="w3l-two-servicses py-5" id="services2">
    <div class="container py-lg-5 py-md-4 py-2">
     
      <div class="row bottom-ab-grids">
        <div class="col-lg-6 bottom-ab-left align-self">
          <h6 class="title-subhny">What We Offer</h6>
          <h3 class="title-w3l mb-4">Our Advantages</h3>
          <p class="">Lorem ipsum dolor sit amet elit. hic odio tenetur. ante ipsum primis in
            faucibus orci luctus et ultrices posuere cubilia acere aperiam sequi optio consectetur adipisicing
            dolor et amet. </p>
          <ul class="w3l-right-book mt-4">
            <li><span class="fa fa-check"></span> Insights-Driven Decision-Making</li>
            <li><span class="fa fa-check"></span> Streamlined Workflow</li>
            <li><span class="fa fa-check"></span> Revenue Optimization</li>
            <li><span class="fa fa-check"></span> Enhanced Cash Flow Management</li>
          </ul>
        </div>
        <div class="col-lg-6 bottom-right-grids pl-lg-5 mt-lg-0 mt-5">
          <img src="assets/images/g4.jpg" alt="" class="radius-image img-fluid"/>
        </div>
      </div>
    </div>
  </section>
  {/* //home page block grids */}
  {/* /w3l-content-5*/}
  <section class="w3l-content-5 py-5">
    <div class="content-4-main py-lg-5 py-md-4">
      <div class="container pb-5">
        <div class="title-content text-left">
          <h6 class="title-subhny">Here & Now, Every Day</h6>
          <h3 class="title-w3l two mb-sm-5 mb-4">Healing Experiences- For Everyone <br/> All The Time</h3>
        </div>
        <div class="content-info-in row">
          <div class="content-left col-lg-6">
            <p class=""> We focus on the needs of every small to middle market businesses to improve and grow
              their return. lacinia id erat eu
              ullam corper. Nunc id ipsum fringilla, gravida felis vitae. lacinia id, sunt in
              culpa quis lacinia. Lorem ipsum dolor, sit amet init elit. Eos,
              debitis doler et in.lacinia id, sunt in culpa quis. </p>
            <a href="#discover" class="btn btn-style btn-primary mt-md-5 mt-4">Get in touch</a>
          </div>
          <div class="content-right col-lg-6 mt-lg-0 mt-5">
            <p> We focus on the needs of every small to middle market businesses to improve and grow
              their return. lacinia id erat eu
              ullam corper. Nunc id ipsum fringilla, gravida felis vitae. lacinia id, sunt in
              culpa quis lacinia. Lorem ipsum dolor, sit amet init elit. Eos,
              debitis. gravida felis vitae. lacinia id, sunt in
              culpa quis. Lorem ipsum dolor sit amet</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* /w3l-content-5*/}
  {/*/team-sec*/}
  <section class="w3l-team">
    <div class="team py-5">
      <div class="container py-lg-5 py-md-4">
        <div class="title-content text-center">
         
          <h3 class="title-w3l mb-sm-3 text-center">Leading Team</h3>
        </div>
        <div class="row team-row">
          <div class="col-lg-3 col-6 team-wrap mt-4 pt-lg-2">
            <div class="team-info">
              <div class="column position-relative img-circle">
                <a href="#url"><img src="assets/images/team1.jpg" alt="" class="img-fluid" /></a>
              </div>
              <div class="column-btm">
                <h3 class="name-pos"><a href="#url">Jennifer Foster
                </a></h3>
                <p>Associate Dentist</p>
                <div class="social">
                  <a href="#facebook" class="facebook"><span class="fa fa-facebook" aria-hidden="true"></span></a>
                  <a href="#twitter" class="twitter"><span class="fa fa-twitter" aria-hidden="true"></span></a>
                </div>
              </div>
            </div>
          </div>
          {/* end team member */}


          <div class="col-lg-3 col-6 team-wrap mt-4 pt-lg-2">
            <div class="team-info">
              <div class="column position-relative img-circle">
                <a href="#url"><img src="assets/images/team2.jpg" alt="" class="img-fluid" /></a>
              </div>
              <div class="column-btm">
                <h3 class="name-pos"><a href="#url">Dhony Abraham</a></h3>
                <p>Orthodontist</p>
                <div class="social">
                  <a href="#facebook" class="facebook"><span class="fa fa-facebook" aria-hidden="true"></span></a>
                  <a href="#twitter" class="twitter"><span class="fa fa-twitter" aria-hidden="true"></span></a>
                </div>
              </div>
            </div>

          </div>
          {/* end team member */}

          <div class="col-lg-3 col-6 team-wrap mt-4 pt-lg-2">

            <div class="team-info">
              <div class="column position-relative img-circle">
                <a href="#url"><img src="assets/images/team3.jpg" alt="" class="img-fluid" /></a>
              </div>
              <div class="column-btm">
                <h3 class="name-pos"><a href="#url">Marko Dugonji</a></h3>
                <p>Health Therapist</p>
                <div class="social">
                  <a href="#facebook" class="facebook"><span class="fa fa-facebook" aria-hidden="true"></span></a>
                  <a href="#twitter" class="twitter"><span class="fa fa-twitter" aria-hidden="true"></span></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-6 team-wrap mt-4 pt-lg-2">
            <div class="team-info">
              <div class="column position-relative img-circle">
                <a href="#url"><img src="assets/images/team4.jpg" alt="" class="img-fluid" /></a>
              </div>
              <div class="column-btm">
                <h3 class="name-pos"><a href="#url">Rhoda Byrd</a></h3>
                <p>Implantologist</p>
                <div class="social">
                  <a href="#facebook" class="facebook"><span class="fa fa-facebook" aria-hidden="true"></span></a>
                  <a href="#twitter" class="twitter"><span class="fa fa-twitter" aria-hidden="true"></span></a>
                </div>
              </div>
            </div>
          </div>
          {/* end team member */}
        </div>
      </div>
    </div>
  </section>
  {/*//team-sec*/}
  

    </div>
  );
}

export default About;
