// src/components/IncomeExpenseChart.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const IncomeExpenseChart = ({ title, frequency }) => {
    const data = frequency === 'monthly' ? {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Income',
                data: [500, 700, 800, 1000, 1200, 900, 1300, 1400, 1500, 1600, 1700, 1800],
                backgroundColor: '#4caf50',
            },
            {
                label: 'Expenses',
                data: [400, 500, 600, 700, 800, 700, 800, 900, 1000, 1100, 1200, 1300],
                backgroundColor: '#ff7043',
            },
        ],
    } : {
        labels: ['2019', '2020', '2021', '2022', '2023'],
        datasets: [
            {
                label: 'Income',
                data: [12000, 15000, 18000, 20000, 22000],
                backgroundColor: '#4caf50',
            },
            {
                label: 'Expenses',
                data: [10000, 12000, 15000, 16000, 18000],
                backgroundColor: '#ff7043',
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: title,
            },
        },
    };

    return <Bar data={data} options={options} />;
};

export default IncomeExpenseChart;
