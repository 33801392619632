// Income.jsx
import React from 'react';
import { Container, Table, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const isPathActive = (path) => window.location.pathname === path;


const incomeData = [
  { id: 1, source: 'Consultation Fees', amount: 5000, date: '2024-10-01' },
  { id: 2, source: 'Surgery', amount: 15000, date: '2024-10-02' },
  { id: 3, source: 'Laboratory Tests', amount: 7000, date: '2024-10-03' },
  { id: 4, source: 'Pharmacy Sales', amount: 4500, date: '2024-10-04' },
  { id: 5, source: 'Room Rent', amount: 12000, date: '2024-10-05' },
  { id: 6, source: 'Emergency Services', amount: 3000, date: '2024-10-06' },
  { id: 7, source: 'Medical Equipment Rent', amount: 8000, date: '2024-10-07' },
  { id: 8, source: 'Other Services', amount: 2000, date: '2024-10-08' },
];

const Income = () => {
  return (
    <>
      {/* Header */}
      <header id="site-header" className="header-w3l fixed-top">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light w-100">
            <a className="navbar-brand" href="/">
              Med<span className="sub-logo">i</span>ck
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav mx-lg-auto">
              <li className="nav-item">
                  <Link to="/dashboard" className={`nav-link ${isPathActive('/dashboard') ? 'active' : ''}`}>
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/income" className={`nav-link ${isPathActive('/income') ? 'active' : ''}`}>
                    Income
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/expenses" className={`nav-link ${isPathActive('/expenses') ? 'active' : ''}`}>
                    Expenses
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/patients" className={`nav-link ${isPathActive('/patients') ? 'active' : ''}`}>
                    Patients
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/calendar" className={`nav-link ${isPathActive('/calendar') ? 'active' : ''}`}>
                    Calendar
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/profile" className={`nav-link ${isPathActive('/profile') ? 'active' : ''}`}>
                    Profile
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/" className={`nav-link ${isPathActive('/') ? 'active' : ''}`}>
                    Logout
                  </Link>
                </li>
              </ul>
              <form className="d-flex ms-lg-5">
                <input
                  type="search"
                  className="form-control me-8"
                  placeholder="Search here.."
                  aria-label="Search"
                  required
                />
                <button className="btn btn-outline-primary" type="submit">
                  <i className="fa fa-search"></i>
                </button>
              </form>
            </div>
          </nav>
        </div>
      </header>


      {/* Main Content */}
      <div className="main-content" style={{ backgroundColor: '#1c2d55' }}>
      <Container fluid className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh', paddingTop: '5rem' }}>
        <Row className="w-75">
          <Col>
            <Card className="shadow-lg border-0">
              <Card.Header className="bg-primary text-light text-center">
                <h2 className="mb-0">Hospital Income Details</h2>
              </Card.Header>
              <Card.Body>
                <Table responsive bordered hover className="text-center">
                  <thead className="table-primary">
                    <tr>
                      <th>#</th>
                      <th>Income Source</th>
                      <th>Amount (INR)</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {incomeData.map((income) => (
                      <tr key={income.id}>
                        <td>{income.id}</td>
                        <td>{income.source}</td>
                        <td>{income.amount.toLocaleString()}</td>
                        <td>{income.date}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer className="text-muted text-center">
                Total Records: {incomeData.length}
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
      </div>
    </>
  );
};

export default Income;
