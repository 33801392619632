// WorkCalendar.js
import React from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const locales = { 'en-US': require('date-fns/locale/en-US') };
const localizer = dateFnsLocalizer({ format, parse, startOfWeek, getDay, locales });

const events = [
  { title: 'Holiday', start: new Date(2024, 11, 25), end: new Date(2024, 11, 25) },
  { title: 'Working Day', start: new Date(2024, 11, 26), end: new Date(2024, 11, 26) },
];

const WorkCalendar = () => {
  return <Calendar localizer={localizer} events={events} startAccessor="start" endAccessor="end" style={{ height: 300 }} />;
};

export default WorkCalendar;
