import React from 'react'
import { Link } from 'react-router-dom';

const isPathActive = (path) => window.location.pathname === path;

function contact() {
  return (
    <div>
      {/* Header */}
      <header id="site-header" className="header-w3l fixed-top">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light w-100"
            style={{ backgroundColor: 'gray' }}>
            <a className="navbar-brand" href="/">
              Med<span className="sub-logo">i</span>ck
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav mx-lg-auto">
                <li className="nav-item">
                  <Link to="/" className={`nav-link ${isPathActive('/') ? 'active' : ''}`}>
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/about" className={`nav-link ${isPathActive('/about') ? 'active' : ''}`}>
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/services" className={`nav-link ${isPathActive('/services') ? 'active' : ''}`}>
                    Department
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className={`nav-link ${isPathActive('/contact') ? 'active' : ''}`}>
                    Contact
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/login" className={`nav-link ${isPathActive('/login') ? 'active' : ''}`}>
                    Login
                  </Link>
                </li>
              </ul>
              <form className="d-flex ms-lg-3">
                <input
                  type="search"
                  className="form-control me-2"
                  placeholder="Search here.."
                  aria-label="Search"
                  required
                />
                <button className="btn btn-outline-primary" type="submit">
                  <i className="fa fa-search"></i>
                </button>
              </form>
            </div>
          </nav>
        </div>
      </header>


        {/* Breadcrumb */}
        <div className="breadcrumb-bg py-5 w3l-inner-page-breadcrumb">
        <div className="container pt-lg-5 pt-md-3 p-lg-4 pb-md-3 my-lg-3">
          <h2 className="title pt-5">Contact Us</h2>
          <ul className="breadcrumbs-custom-path mt-3 text-center">
            <li><a href="/">Home</a></li>
            <li className="active"><span className="fa fa-arrow-right mx-2" aria-hidden="true"></span> Contact</li>
          </ul>
        </div>
      </div>
      {/* Banner Bottom Shape */}
      <div className="position-relative">
        <div className="shape overflow-hidden">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
          </svg>
        </div>
      </div>
      {/* Contact Section */}
      <section className="w3l-contact-2 py-5" id="contact">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="contact-grids d-grid">
            <div className="contact-left">
              <h6 className="title-subhny mb-1">Get in Touch</h6>
              <h3 className="title-w3l mb-2">Contact Us</h3>
              <p>We have a dedicated support center for all of your support needs. We usually get back to you within 12-24 hours.</p>
              <div className="cont-details">
                <div className="cont-top margin-up">
                  <div className="cont-left text-center">
                    <span className="fa fa-map-marker"></span>
                  </div>
                  <div className="cont-right">
                    <h6>Our Address</h6>
                    <p>SEO Agency, 343 Medick, #2214 cravel street, NY - 62617.</p>
                  </div>
                </div>
                <div className="cont-top margin-up">
                  <div className="cont-left text-center">
                    <span className="fa fa-phone"></span>
                  </div>
                  <div className="cont-right">
                    <h6>Call Us</h6>
                    <p><a href="tel:+1(21)2344567">+1(21) 234 4567</a></p>
                  </div>
                </div>
                <div className="cont-top margin-up">
                  <div className="cont-left text-center">
                    <span className="fa fa-envelope-o"></span>
                  </div>
                  <div className="cont-right">
                    <h6>Email Us</h6>
                    <p><a href="mailto:example@mail.com" className="mail">example@mail.com</a></p>
                  </div>
                </div>
                <div className="cont-top margin-up">
                  <div className="cont-left text-center">
                    <span className="fa fa-life-ring"></span>
                  </div>
                  <div className="cont-right">
                    <h6>Customer Support</h6>
                    <p><a href="mailto:info@support.com" className="mail">info@support.com</a></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-right">
              <form action="https://sendmail.w3layouts.com/submitForm" method="post" className="signin-form">
                <div className="input-grids">
                  <input type="text" name="w3lName" id="w3lName" placeholder="Your Name*" className="contact-input" required />
                  <input type="email" name="w3lSender" id="w3lSender" placeholder="Your Email*" className="contact-input" required />
                  <input type="text" name="w3lSubect" id="w3lSubect" placeholder="Subject*" className="contact-input" required />
                  <input type="text" name="w3lWebsite" id="w3lWebsite" placeholder="Website URL*" className="contact-input" required />
                </div>
                <div className="form-input">
                  <textarea name="w3lMessage" id="w3lMessage" placeholder="Type your message here*" required></textarea>
                </div>
                <div className="w3l-submit text-lg-right">
                  <button className="btn btn-style btn-primary">Send Message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* Map */}
      <div className="map-iframe">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d317718.69319292053!2d-0.3817765050863085!3d51.528307984912544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C+UK!5e0!3m2!1sen!2spl!4v1562654563739!5m2!1sen!2spl"
          width="100%" height="400" frameBorder="0" style={{ border: 0 }} allowFullScreen=""
          aria-hidden="false" tabIndex="0"></iframe>
      </div>

    </div>
  )
}

export default contact