import React, { useState } from 'react';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Calendar.css';
import { Link } from 'react-router-dom';

const isPathActive = (path) => window.location.pathname === path;

function CalendarComponent() {
  const [date, setDate] = useState(new Date());

  const holidays = [
    new Date(2024, 11, 25), // December 25, 2024
    new Date(2024, 0, 1),   // January 1, 2024
  ];

  const workingDays = Array.from({ length: 31 }, (_, i) => new Date(2024, 10, i + 1));

  const isHoliday = (date) => holidays.some(holiday => holiday.toDateString() === date.toDateString());
  const isWorkingDay = (date) => workingDays.some(workingDay => workingDay.toDateString() === date.toDateString());

  const tileContent = ({ date }) => {
    if (isHoliday(date)) {
      return <div className="holiday">Holiday</div>;
    } else if (isWorkingDay(date)) {
      return <div className="working-day">Working</div>;
    }
    return null;
  };

  return (
    <div style={{ backgroundColor: '#1c2d55', minHeight: '100vh', paddingTop: '100px', color: '#fff' }}>
      <header className="header-w3l fixed-top">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light w-100" style={{ backgroundColor: '#1c2d55' }}>
            <a className="navbar-brand" href="/">
              Med<span className="sub-logo">i</span>ck
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav mx-lg-auto">
                <li className="nav-item">
                  <Link to="/dashboard" className={`nav-link ${isPathActive('/dashboard') ? 'active' : ''}`}>
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/income" className={`nav-link ${isPathActive('/income') ? 'active' : ''}`}>
                    Income
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/expenses" className={`nav-link ${isPathActive('/expenses') ? 'active' : ''}`}>
                    Expenses
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/patients" className={`nav-link ${isPathActive('/patients') ? 'active' : ''}`}>
                    Patients
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/calendar" className={`nav-link ${isPathActive('/calendar') ? 'active' : ''}`}>
                    Calendar
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/profile" className={`nav-link ${isPathActive('/profile') ? 'active' : ''}`}>
                    Profile
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/" className={`nav-link ${isPathActive('/') ? 'active' : ''}`}>
                    Logout
                  </Link>
                </li>
              </ul>
              <form className="d-flex ms-lg-5 mt-2 mt-lg-0">
                <input type="search" className="form-control me-2" placeholder="Search here.." aria-label="Search" required />
                <button className="btn btn-outline-primary" type="submit">
                  <i className="fa fa-search"></i>
                </button>
              </form>
            </div>
          </nav>
        </div>
      </header>

      <div className="calendar-main-container">
        <div className="calendar-container">
          <Calendar
            onChange={setDate}
            value={date}
            tileContent={tileContent}
            tileClassName={({ date }) => {
              if (isHoliday(date)) return 'holiday';
              if (isWorkingDay(date)) return 'working-day';
              return '';
            }}
          />
        </div>
        
        <div className="calendar-info mt-4">
          <div className="info-card">
            <h3>Total Working Days</h3>
            <p>{workingDays.length}</p>
          </div>
          <div className="info-card">
            <h3>Total Holidays</h3>
            <p>{holidays.length}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CalendarComponent;
