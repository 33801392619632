import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link } from 'react-router-dom';

const isPathActive = (path) => window.location.pathname === path;

// Dummy data for patients
const patientsData = Array.from({ length: 30 }, (_, i) => ({
  id: `P${100 + i}`,
  name: `Patient ${i + 1}`,
  age: Math.floor(Math.random() * 60) + 20, // Random age between 20 and 80
  gender: i % 2 === 0 ? 'Male' : 'Female',
  bookingDate: new Date(2024, Math.floor(Math.random() * 12), Math.floor(Math.random() * 28) + 1).toLocaleDateString(),
  status: i % 3 === 0 ? 'Pending' : 'Confirmed'
}));

function Patients() {
  return (
   <div className=''  style={{ paddingTop: '100px' , backgroundColor:'#1c2d55' }}  >
    {/* Header */}
    <header id="site-header" className="header-w3l fixed-top">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light w-100"  
            style={{ backgroundColor: '#1c2d55' }}  > 
            <a className="navbar-brand" href="/">
              Med<span className="sub-logo">i</span>ck
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav mx-lg-auto">
              <li className="nav-item">
                  <Link to="/dashboard" className={`nav-link ${isPathActive('/dashboard') ? 'active' : ''}`}>
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/income" className={`nav-link ${isPathActive('/income') ? 'active' : ''}`}>
                    Income
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/expenses" className={`nav-link ${isPathActive('/expenses') ? 'active' : ''}`}>
                    Expenses
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/patients" className={`nav-link ${isPathActive('/patients') ? 'active' : ''}`}>
                    Patients
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/calendar" className={`nav-link ${isPathActive('/calendar') ? 'active' : ''}`}>
                    Calendar
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/profile" className={`nav-link ${isPathActive('/profile') ? 'active' : ''}`}>
                    Profile
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/" className={`nav-link ${isPathActive('/') ? 'active' : ''}`}>
                    Logout
                  </Link>
                </li>
              </ul>
              <form className="d-flex ms-lg-5">
                <input
                  type="search"
                  className="form-control me-8"
                  placeholder="Search here.."
                  aria-label="Search"
                  required
                />
                <button className="btn btn-outline-primary" type="submit">
                  <i className="fa fa-search"></i>
                </button>
              </form>
            </div>
          </nav>
        </div>
      </header>

      <div className="container mt-5 bg-light">
        <br />
        <h2 className="text-center mb-4 text-white bg-primary">Booked Patients List</h2>
        <div className="table-responsive">
          <table className="table table-hover table-bordered">
            <thead className="table-primary">
              <tr>
                <th>Patient ID</th>
                <th>Name</th>
                <th>Age</th>
                <th>Gender</th>
                <th>Booking Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {patientsData.map((patient) => (
                <tr key={patient.id}>
                  <td>{patient.id}</td>
                  <td>{patient.name}</td>
                  <td>{patient.age}</td>
                  <td>{patient.gender}</td>
                  <td>{patient.bookingDate}</td>
                  <td>
                    <span className={`badge ${patient.status === 'Confirmed' ? 'badge-success' : 'badge-warning'}`}>
                      {patient.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Patients;
